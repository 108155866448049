import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import classnames from 'classnames';
import Mousetrap from 'mousetrap';
import slugify from 'helpers/slugify';
import removeBr from 'helpers/removeBr';
import colorTheme from 'helpers/colorTheme';
import Metas from 'components/metas';
import Billboard from 'components/billboard';
import BlogPostCard from 'components/blogPostCard';
import FeaturedBlogPostCard from 'components/featuredBlogPostCard';
import { Choosy, Option } from 'components/choosy';
import SearchInput from 'components/searchInput';
import SmartLink from 'components/smartLink';
import { ReactComponent as SearchIcon } from 'images/icon-search.svg';
import { ReactComponent as XIcon } from 'images/icon-x.svg';
import styles from './blog.module.scss';

const isClient = typeof window !== 'undefined';

export default function BlogPage({ location }) {
  /*
   *   Query blog posts
   */

  const blogPostData = useStaticQuery(graphql`
    query BlogPostsQuery {
      allContentfulBlogPost(sort: { fields: publishDate, order: DESC }) {
        nodes {
          ... on Node {
            ... on ContentfulBlogPost {
              id
              title
              slug
              publishDate
              blogCategory {
                id
                title
                subtitle
                description {
                  description
                }
                colorTheme
              }
              tags
              author {
                name
              }
              authorTwo {
                name
              }
              heroImage {
                fluid(maxWidth: 700) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
                title
              }
            }
          }
        }
      }
    }
  `);

  const [currentCat, setCurrentCat] = useState(null);
  const [numberOfPosts, setNumberOfPosts] = useState(17);
  const [searchResults, setSearchResults] = useState([]);
  const [searchIsOpen, setSearchIsOpen] = useState(false);
  const searchButtonRef = useRef(null);
  const searchInputRef = useRef(null);
  const searchContainerRef = useRef(null);
  const posts = blogPostData.allContentfulBlogPost.nodes;
  const filteredPosts = posts.filter(
    (p) =>
      p.blogCategory &&
      (p.blogCategory.id === currentCat || currentCat === null)
  );
  const featured = filteredPosts[0];
  const categories = posts.reduce(
    (acc, curr) =>
      curr.blogCategory
        ? { ...acc, [curr.blogCategory.id]: curr.blogCategory }
        : acc,
    {}
  );
  const colorClass = currentCat ? categories[currentCat].colorTheme : null;
  const currentCatObj = currentCat
    ? categories[currentCat]
    : { title: 'All posts' };

  /*
   *   Display search results
   */
  function handleSearch(results) {
    setSearchResults(results);
  }

  /*
   *   Set current Category
   */
  function setCurrentCategory(id) {
    setTimeout(() => setCurrentCat(id), 50);
  }

  /*
   *   Handle open search doc click
   */
  const handleSearchDocClick = useCallback(
    (evt) => {
      if (searchContainerRef.current.contains(evt.target)) return;
      closeSearch();
    },
    [] //eslint-disable-line
  );

  /*
   *   Open Search
   */
  function openSearch() {
    setSearchIsOpen(true);
    Mousetrap.bind('esc', closeSearch);
    document.addEventListener('click', handleSearchDocClick);
    searchInputRef.current.focus();
  }

  /*
   *   Close Search
   */
  function closeSearch() {
    setSearchIsOpen(false);
    searchButtonRef.current.focus();
    Mousetrap.unbind('esc');
    document.removeEventListener('click', handleSearchDocClick);
  }

  /*
   *   Set Category
   */
  function setCat(cat) {
    setCurrentCategory(cat);
    const catObj = categories[cat];
    window.location.hash = cat ? slugify(catObj.title).toLowerCase() : '';
  }

  /*
   *   House keeping
   */
  useEffect(() => {
    return () => {
      Mousetrap.unbind('esc');
      document.removeEventListener('click', handleSearchDocClick);
    };
  }, [handleSearchDocClick]);

  /*
   *   hash-based tab nav
   */
  useEffect(() => {
    if (
      isClient &&
      location.hash &&
      slugify(currentCatObj.title).toLowerCase() !== slugify(location.hash)
    ) {
      const category = Object.values(categories).filter(
        (cat) => slugify(cat.title).toLowerCase() === slugify(location.hash)
      )[0];
      if (category) setCurrentCat(category.id);
    }
  }, []); //eslint-disable-line

  /*
   *   Dumb hard-coded footer text
   */
  const footerText = {
    json: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Ready to engage, support, and grow every learner?',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
  };

  /*
   *   Post els
   */

  const postEls = filteredPosts.map((p, i) => {
    if (i === 0 || i >= numberOfPosts) return null;

    return (
      <BlogPostCard
        key={p.id}
        title={p.title}
        category={p.blogCategory.title}
        colorTheme={p.blogCategory.colorTheme}
        author={p.author.name}
        authorTwo={p.authorTwo?.name ?? null}
        date={p.publishDate}
        image={p.heroImage}
        slug={p.slug}
        className={classnames(styles.card, {
          [styles.small]: i > 4,
          [styles.medium]: i <= 4,
        })}
      />
    );
  });

  /*
   *   Choosy category els
   */

  const catOrder = [
    'The District',
    'The Classroom',
    'The Debrief',
    'The Company',
  ];
  const choosyCategoryEls = Object.keys(categories)
    .sort((a, b) => {
      const aIndex = catOrder.indexOf(categories[a].title).toString();
      const bIndex = catOrder.indexOf(categories[b].title).toString();
      return aIndex.localeCompare(bIndex);
    })
    .map((c) => {
      const cat = categories[c];
      return (
        <Option
          key={cat.id}
          className={classnames(styles.choosy__option, {
            [styles.current]: currentCat === cat.id,
          })}
          onClick={() => setCat(cat.id)}
        >
          <span className={styles.choosy__option_title}>
            {removeBr(cat.title)}
          </span>{' '}
          <span className={styles.choosy__option_subtitle}>{cat.subtitle}</span>
        </Option>
      );
    });

  /*
   *   Search input
   */
  const searchInputEl = useMemo(
    () => (
      <SearchInput
        tabIndex={searchIsOpen ? null : -1}
        indexIdField="id"
        indexFields={[
          'title',
          ['category', 'title'],
          'tags',
          ['author', 'name'],
        ]}
        documents={posts}
        className={classnames(styles.search__input, 'mousetrap')}
        inputRef={searchInputRef}
        onSearch={handleSearch}
        clear={searchIsOpen}
      />
    ),
    [posts, searchIsOpen]
  );

  /*
   *   Search Result Els
   */
  const searchResultEls = searchResults.map((r) => (
    <SmartLink
      to={`/blog/${r.slug}`}
      className={styles.search__result}
      key={r.id}
    >
      {r.title}
    </SmartLink>
  ));

  /*
   *   Search Container El
   */
  const searchEl = (
    <div
      className={classnames(styles.search, {
        [styles.open]: searchIsOpen,
      })}
      ref={searchContainerRef}
    >
      <div className={styles.search__form}>
        <SearchIcon />
        <div className={styles.search__form_inner}>
          {searchInputEl}
          <button
            className={styles.search__close}
            onClick={closeSearch}
            tabIndex={searchIsOpen ? null : -1}
          >
            <XIcon />
          </button>
        </div>
      </div>
      <div className={styles.search__results}>{searchResultEls}</div>
    </div>
  );

  /*
   *   Render
   */
  return (
    <>
      <Metas
        title="Education News, Updates & Blog"
        description="Access news articles and resources from our team to help educators. The Newsela blog is your news source for all things teaching, education, and Newsela."
      />
      <section
        className={classnames(styles.hero, { [styles.filtered]: !!currentCat })}
      >
        <div
          className={styles.hero__background}
          style={colorTheme('background-color', colorClass)}
        ></div>
        <div className={styles.hero__inner}>
          <header className={styles.hero__header}>
            <Choosy className={styles.choosy} label={currentCatObj.title}>
              <Option
                className={classnames(styles.choosy__option, {
                  [styles.current]: !currentCat,
                })}
                onClick={() => setCat(null)}
              >
                All posts
              </Option>
              {choosyCategoryEls}
            </Choosy>
            <button
              ref={searchButtonRef}
              className={styles.search_button}
              onClick={openSearch}
            >
              <SearchIcon />
            </button>
            {searchEl}
          </header>
          <div
            className={styles.cat_header}
            style={colorTheme('background-color', colorClass)}
          >
            <h2 className={styles.cat_title}>{currentCatObj.title}</h2>
            <div className={styles.cat_subtitle}>{currentCatObj.subtitle}</div>
          </div>
          <FeaturedBlogPostCard
            className={styles?.featured}
            heroImage={featured?.heroImage}
            category={featured?.blogCategory}
            slug={featured?.slug}
            title={featured?.title}
            author={featured?.author}
            authorTwo={featured?.authorTwo}
            publishDate={featured?.publishDate}
          />
        </div>
      </section>

      <section className={styles.posts}>
        <div className={styles.posts__inner}>{postEls}</div>
        {numberOfPosts < filteredPosts.length && (
          <footer className={styles.posts__footer}>
            <button
              className={styles.more_button}
              onClick={() => setNumberOfPosts(numberOfPosts + 6)}
            >
              Load more
            </button>
          </footer>
        )}
      </section>

      <Billboard
        title="Inspire the desire to learn."
        subtitle={footerText}
        buttonText="Contact us"
        buttonUrl="https://go.newsela.com/contact"
        backgroundColor="Blue"
      />
    </>
  );
}
