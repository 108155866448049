import React from 'react';
import classnames from 'classnames';
import Img from 'gatsby-image/withIEPolyfill';
import formatDate from 'helpers/formatDate';
import SmartLink from 'components/smartLink';
import styles from './featuredBlogPostCard.module.scss';

export default function featuredBlogPostCard({
  heroImage,
  category,
  slug,
  title,
  author,
  publishDate,
  className,
}) {
  return (
    <article
      className={classnames(
        styles.featured,
        className,
        styles[category?.colorTheme]
      )}
    >
      <SmartLink className={styles.featured__inner} to={`/blog/${slug}`}>
        <div className={styles.featured__content}>
          <h3 className={styles.featured__title}>{title}</h3>
          <footer className={styles.featured__footer}>
            <div className={styles.featured__author}>{author?.name}</div>
            <div className={styles.featured__date}>
              {formatDate(publishDate)}
            </div>
          </footer>
        </div>
        {heroImage && (
          <Img
            className={styles.featured__image}
            objectFit="contain"
            objectPosition="center center"
            fluid={heroImage.fluid}
            alt={heroImage.title}
          />
        )}
      </SmartLink>
    </article>
  );
}
